import { environment as EduEnv } from "./Edu";
import { MansioniEnv } from "../Modules/Mansioni";
import { addModule, generateEnv } from "../Utility/ModuleUtility";
import { FabbisognoFormativoEnv } from "../Modules/FabbisognoFormativo";
import { env } from "process";
var Environment = () => {
    var env = {
        logoPath: 'assets/images/wcs.jpeg',
        poweredBy: null,
        MainColor: null,
        navbarbg: 'skin2',
        navbarAccentColor: null,
        style:null,
        InputTextColor: null,
        FormazioneAddOn: {
            light: false,
            hideLight: true,
            hideChiusa: true,
            hideColor: true,
            usemodal: false,
            EdizioneCampiAgg: [
                { nome: "Azienda", oggetti: ['cliente'], modificabile: true, class: 'col-lg-3', },
                { nome: "Sede", oggetti: ['sede'], modificabile: true, class: 'col-lg-3', },
                { nome: "Città", oggetti: ['citta'], modificabile: true, class: 'col-lg-3', },
                { nome: "Docente", oggetti: ['docente'], modificabile: true, class: 'col-lg-3', },

            ]
        },
        Personale: {
            Tabs: [
                { id: 'Mansioni', titolo: 'Mansioni', tipo: 'crud', crud: 'mansioni', hideif: { fieldnotexist: 'persona_id' } },
                { id: 'Fabbisogno', titolo: 'Fabbisogno', tipo: 'crud', crud: 'fabbisogno', hideif: { fieldnotexist: 'persona_id' } },
            ],
            RefTabs: [
                { id: 'Mansioni', titolo: 'Mansioni', tipo: 'crud', crud: 'mansioni', hideif: { fieldnotexist: 'persona_id' } },
                { id: 'Fabbisogno', titolo: 'Fabbisogno', tipo: 'crud', crud: 'fabbisogno', hideif: { fieldnotexist: 'persona_id' } },
                ]
        },
    }
    var newEnv = addModule(MansioniEnv(), env);
    newEnv = addModule(FabbisognoFormativoEnv(), newEnv);

    return generateEnv(EduEnv, newEnv)
}
export var environment: any = Environment();