export const Scaduta = (dateString) => {
    // Controlla il formato della stringa usando una regex
    var regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
        return false; // Formato non valido
    }

    // Converte la stringa in oggetto Date
    var inputDate = new Date(dateString);
    if (isNaN(inputDate.getTime())) {
        return false; // Data non valida
    }

    // Calcola la data di oggi a mezzanotte
    var today = new Date();
    today.setHours(0, 0, 0, 0);

    // Confronta se la data è già passata
    return inputDate < today;
};

export const isDateNearExpiry = (dateString, daysUntilExpiry) => {
    // Controlla il formato della stringa usando una regex
    var regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
        return false; // Formato non valido
    }

    // Converte la stringa in oggetto Date
    var inputDate = new Date(dateString);
    if (isNaN(inputDate.getTime())) {
        return false; // Data non valida
    }

    // Calcola la data di oggi
    var today = new Date();

    // Calcola la differenza in giorni
    var differenceInTime = inputDate.getTime() - today.getTime();
    var differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

    // Verifica se la data è prossima alla scadenza
    return differenceInDays <= daysUntilExpiry && differenceInDays >= 0;
};

export const Ieri = () => {
    var data = new Date();
    data.setDate(data.getDate() - 1);
    return data;
}
export const Anno = () => {
    var data = new Date();
    return data.getFullYear();
}