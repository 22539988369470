/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2024.
 * Title: FabbisognoFormativo.ts;
 * Path: /Volumes/Dati/SbSW_SoftwareBySystem_Works/Fortyfix/GESFF_Gestionale_FF_Angular/src/environments/Modules/FabbisognoFormativo.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 19/12/24;
 * Updated at: 19/12/24;
 */

import { ComponentRef, TemplateRef, ViewContainerRef } from "@angular/core";
import { Permission } from "src/app/Login/_guards/Permission";
import { ApiPathsService } from "src/app/api-paths.service";
import { Alert } from "src/app/shared/Alerts/Alert.component";
import { OggettoConfig } from "src/app/shared/_services/dynamic-crud.service";
import { Scaduta, isDateNearExpiry } from "src/environments/Utility/DateUtility";

function FabbisognoFormativo() {
    var OBJ: OggettoConfig = {
        HasAddList: {},//da usare come hideif
        Capacita: ['gestione-fabbisogno'],
        Id: "fabbisogno",
        listTitle: "Training",
        RestRoute: '/anagrafica/clienti/fabbisogno',
        ListResponseHasData: true,
        GetResponseHasData: false,
        ListDefaultFilter: {},
        OnSaveMap: (x) => { { return x } },
        MenuItems: [
            {
                path: "/shared/dynamiclist/fabbisogno",
                title: "Training",
                icon: "fas fa-user-graduate",
                class: "",
                capacita: ['lista-fabbisogno'],
            },
        ],
        campi: [
            {
                nome: 'Data',
                oggetti: ['data'],
                modificabile: true,
                inputType: 'date',
                required: true,
                class: 'col-lg-3'
            },
            {
                nome: 'Data Scadenza',
                oggetti: ['data_fine'],
                modificabile: true,
                inputType: 'date',
                required: true,
                class: 'col-lg-3' },
            // {
            //     nome: 'Provenienza',
            //     oggetti: ['provenienza'],
            //     source: '/anagrafica/clienti/fabbisogno/provenienze',
            //     internalOutput: 'provenienzaObject',
            //     OutputField: 'provenienza',
            //     OnChange: 'OnprovenienzaChange',
            //     modificabile: true,
            //     inputType: 'singleDropdown',
            //     placeholder: 'Seleziona',
            //     settings: {
            //         singleSelection: true,
            //         closeDropDownOnSelection: true,
            //         singleOutput: true,
            //         idField: 'id',
            //         textField: 'label',
            //         searchPlaceholderText: "Cerca",
            //         allowSearchFilter: true,
            //         allowRemoteDataSearch: false,
            //     },
            //     class: 'col-lg-3',
            //     visibleifProhibited: true
            // },
            {
                nome: 'Personale',
                hideif: {
                    func: (oggetto: any, permission: Permission) => {
                        return !oggetto.persona_id && !oggetto.fromLavoratoriList
                }},
                oggetti: ['persona_id'],
                source: '/servizi/edizioni/persone',
                responseHasData: true,
                parameter: [{ nome: "page", value: 1 }, { nome: "size", value: 2000 }],
                internalOutput: 'personaObject',
                OutputField: 'persona_id',
                OnChange: 'OnpersonaChange',
                required: true,
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'fullName',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-3',
                visibleifProhibited: true
            },
            {
                nome: 'Fabbisogno',
                oggetti: ['fabbisogno'],
                required: true,
                modificabile: true,
                inputType: 'text',
                tooltip: 'Indicare il tipo di training richiesta; se possibile, identificare il tipo di corso o di affiancamento fornendo dettagli che possono agevolare l’eventuale pianificazione',
                class: 'col-lg-3'
            },
            // {
            //     nome: 'Motivazione',
            //     oggetti: ['motivazione'],
            //     required: true,
            //     modificabile: true,
            //     inputType: 'text',
            //     class: 'col-lg-3'
            // },
            {
                nome: 'Note',
                oggetti: ['note'],
                modificabile: true,
                required: false,
                inputType: 'textarea',
                class: 'col-lg-12'
            },
        ],
        AzioniLista: [
            {
                nome: 'Concludi',
                close: true,
                icon: 'fa fa-check text-success',
                class: ' btn ',
                labelClass: 'text-success ',
                type: 'edit',
                tooltip: "Completa",
                noEditFill: true,
                customSave: (task, api:ApiPathsService, originalTask, campiModificati) => { 
                    originalTask.chiuso = true;
                    // console.log('originalTask', originalTask);
                    // console.log('task', task);
                    // console.log('campiModificati', campiModificati);
                    api.ClassicPost('/anagrafica/clienti/fabbisogno/update', Object.assign(originalTask, { id: task.id })).subscribe(() => {
                        task.chiuso = false;
                        task.data_fine = campiModificati?.data_fine;
                        task.corso = undefined;
                        if (campiModificati.data || campiModificati.data_fine)
                        api.ClassicPost('/anagrafica/clienti/fabbisogno/add', task).subscribe(() => {

                        })
                    })

                },
                campi: [
                    { nome: 'Inserire le date di validità del prossimo piano se lo si vuole creare.', oggetti: ['provola'], class: 'col-lg-12' },
                    {
                        nome: 'Data',
                        oggetti: ['data'],
                        modificabile: true,
                        inputType: 'date',
                        class: 'col-lg-3'
                    },
                    {
                        nome: 'Data Scadenza',
                        oggetti: ['data_fine'],
                        modificabile: true,
                        inputType: 'date',
                        class: 'col-lg-3'
                    },
                ],
                // taskType: 'E',
                // taskFilter: 'DI',
            },
            {
                nome: 'Assegna Corso',
                close: true,
                icon: 'icon-corsi text-info',
                class: ' btn ',
                labelClass: 'text-info ',
            
                type: 'edit',
                campi: [
                    {
                        nome: 'Corso',
                        oggetti: ['corso'],
                        parameter: [{ nome: "page", value: 0 }, { nome: "size", value: 200 }, { nome: "chiusa", value: false }],
                        responseHasData: true,
                        source: '/servizi/edizioni/list',
                        internalOutput: 'corsoObject',
                        OutputField: 'corso',
                        modificabile: true,
                        inputType: 'singleDropdown',
                        placeholder: 'Seleziona',
                        settings: {
                            singleSelection: true,
                            closeDropDownOnSelection: true,
                            singleOutput: true,
                            idField: 'id',
                            textField: 'nome',
                            searchPlaceholderText: "Cerca",
                            allowSearchFilter: true,
                            allowRemoteDataSearch: false,
                        },
                        class: 'col-lg-12',
                        visibleifProhibited: true
                    },
                ],
                // taskType: 'E',
                // taskFilter: 'DI',
            },
        ],
        Azioni: [
            {
                nome: 'Elimina',
                close: true,
                class: ' btn ',
                labelClass: 'ml-1 text-danger font-weight-bold',
                type: 'POST',
                restRoute: '/anagrafica/clienti/fabbisogno/delete',
                preAlert: 'Sei sicuro di voler eliminare questo elemento?',
                capacita: ['gestione-fabbisogno'],
                icon: 'fas fa-trash text-danger',
                hideif: { fieldnotexist: 'id' },
                placeholder: 'Elimina',
                successMex: 'Elemento eliminato con successo',
                parameter: [{ nome: "id", parameter: 'id' }]
            }
        ],
        Headers: [
            { nome: 'Data', oggetti: ['data'], inputType: 'date' },
            { nome: 'Data Scadenza', oggetti: ['data_fine'], inputType: 'date' },
            // { nome: 'Provenienza', oggetti: ['provenienzaObject'], field: ['label'], type: 'object', inputType: 'text' },
            { nome: 'Personale', oggetti: ['personaObject'], field: ['fullName'], type: 'object', inputType: 'text' },
            { nome: 'Fabbisogno', oggetti: ['fabbisogno'], inputType: 'text' },
            { nome: 'Completato', oggetti: ['chiuso'], inputType: 'checkbox' },
            {
                nome: 'Stato', type: 'html', printable:false, oggetti: ['test'], func: (oggetto, parent) => {
                    var alertScadenza = `
                        <a href="javascript:void(0)" tooltip="In scadenza" class=" status-icon link font-16 text-danger font-weight-bold" >
                            <i class="fas fa-exclamation-triangle text-warning"></i>
                            <span class="tooltiptext"> In scadenza</span>
                        </a>
                    `
                    var alertScaduta = `
                        <a href="javascript:void(0)" class=" status-icon link font-16 text-danger font-weight-bold" >
                            <i class="fas fa-exclamation-triangle text-danger"></i>
                            <span class="tooltiptext"> Scaduta</span>
                        </a>
                    `
                    if (Scaduta(oggetto.data_fine))
                        return [alertScaduta,];
                    else if (isDateNearExpiry(oggetto.data_fine, 30))
                        return [alertScadenza,];
                    else return [];
                        
                    // return [{ component: Alert, params: { tooltip: 'In scadenza', aclass: null, iclass: null, color: null } }];
            }},
            { nome: 'Note', oggetti: ['note'], inputType: 'text' },

            // {
            //     nome: 'Stato',
            //     oggetti: ['esito'],
            //     source: '/anagrafica/clienti/fabbisogno/esiti',
            //     internalOutput: 'esitoObject',
            //     OutputField: 'esito',
            //     OnChangeFunc: (task, selected, api: ApiPathsService) => {
            //         if (selected?.id)
            //             api.ClassicPost('/anagrafica/clienti/fabbisogno/get', { id: task.id }).subscribe(data => {
            //                 data.esito = selected.id;
            //                 api.ClassicPost('/anagrafica/clienti/fabbisogno/update', data).subscribe();
            //             })
            //     },
            //     modificabile: true,
            //     inputType: 'singleDropdown',
            //     placeholder: 'Seleziona',
            //     settings: {
            //         singleSelection: true,
            //         closeDropDownOnSelection: true,
            //         singleOutput: true,
            //         idField: 'id',
            //         textField: 'label',
            //         searchPlaceholderText: "Cerca",
            //         allowSearchFilter: true,
            //         allowRemoteDataSearch: false,
            //     },
            //     class: 'col-lg-12',
            //     visibleifProhibited: true
            // },
            {
                nome: 'Corso',
                oggetti: ['corsotemp'],
                parameter: [{ nome: "page", value: 0 }, { nome: "size", value: 200 },{nome:"chiusa", value: false}], // mettere persona di riferimento.
                responseHasData: true,
                source: [],
                internalOutput: 'corsoObject',
                OutputField: 'corsotemp',// cambiato seno sotto fa 10000 chiamate
                OnChangeFunc: (task, selected, api: ApiPathsService) => {
                    if (selected?.id != task?.corso)
                        api.ClassicPost('/anagrafica/clienti/fabbisogno/get', { id: task.id }).subscribe(data => {
                            data.corso = selected?.id ?? null;
                            api.ClassicPost('/anagrafica/clienti/fabbisogno/update', data).subscribe();
                        })
                },
                modificabile: false,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'nome',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-12',
                visibleifProhibited: true
            },
        ],
        Filters: [
            { nome: "Inizio", oggetti:["inizio"], modificabile: true, inputType: 'date', class: 'col-lg-3', OnChange: 'refresh',},
            { nome: "Fine", oggetti: ["fine"], modificabile: true, inputType: 'date', class: 'col-lg-3', OnChange: 'refresh', },
            {
                nome: 'Azienda',
                oggetti: ['cliente_id'],
                modificabile: true, 
                source: '/anagrafica/clienti/list',
                responseHasData: true,
                parameter: [{ nome: "page", value: 1 }, { nome: "size", value: 2000 }],
                internalOutput: 'clienteObject',
                OutputField: 'cliente_id',
                OnChange: 'OnclienteChange',
                
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'fullName',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-3',
                visibleifProhibited: true

            },
            {
                nome: 'Sede',
                oggetti: ['sede_id'],
                modificabile: true,
                source: '/anagrafica/clienti/sede/list',
                responseHasData: true,
                parameter: [{ nome: "page", value: 1 }, { nome: "size", value: 2000 }],
                internalOutput: 'sedeObject',
                OutputField: 'sede_id',
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'fullAddress',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-3',
                visibleifProhibited: true

            },
            {
                nome: 'Personale',
                oggetti: ['persona_id'],
                source: '/servizi/edizioni/persone',
                responseHasData: true,
                parameter: [{ nome: "page", value: 1 }, { nome: "size", value: 2000 }],
                internalOutput: 'personaObject',
                OutputField: 'persona_id',
                OnChange: 'OnpersonaChange',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'fullName',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-3',
                visibleifProhibited: true
            },
            // {
            //     nome: 'Stato',
            //     oggetti: ['esito'],
            //     source: '/anagrafica/clienti/fabbisogno/esiti',
            //     internalOutput: 'esitoObject',
            //     OutputField: 'esito',
            //     modificabile: true,
            //     inputType: 'singleDropdown',
            //     placeholder: 'Seleziona',
            //     settings: {
            //         singleSelection: true,
            //         closeDropDownOnSelection: true,
            //         singleOutput: true,
            //         idField: 'id',
            //         textField: 'label',
            //         searchPlaceholderText: "Cerca",
            //         allowSearchFilter: true,
            //         allowRemoteDataSearch: false,
            //     },
            //     class: 'col-lg-3',
            //     visibleifProhibited: true
            // },
            {
                nome: 'Piano di intervento',
                oggetti: ['corsi'],
                parameter: [{ nome: "page", value: 0 }, { nome: "size", value: 200 }, { nome: "chiusa", value: false }],
                responseHasData: true,
                source: '/servizi/edizioni/list',
                internalOutput: 'corsoObject',
                OutputField: 'corsi',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: false,
                    idField: 'id',
                    textField: 'nome',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-3',
                visibleifProhibited: true
            },
            {
                nome: 'Stato',
                oggetti: ['stati'],
                source: [{ id: 'N', label: 'Nuovi' }, { id: 'O', label: 'In corso' }, { id: 'D', label: 'Completati' }],
                internalOutput: 'statiObject',
                OutputField: 'stati',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: false,
                    idField: 'id',
                    textField: 'label',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-3',
                visibleifProhibited: true
            },
        ],
    }
    return OBJ;
}

export var FabbisognoFormativoEnv = () => {

    var env = {
        Cruds: [FabbisognoFormativo()],
        Menu: [...FabbisognoFormativo().MenuItems],
        Modules: {
            "FabbisognoFormativo": true,
        }
    };
    return env;
}

