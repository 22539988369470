import { OggettoConfig } from "src/app/shared/_services/dynamic-crud.service";

function FormazionePersonale() {
    var OBJ: OggettoConfig = {
        HasAddList: {},//da usare come hideif
        Id: "formazione_personale",
        listTitle: "Formazione",
        Capacita: ['gestione-formazione'],
        RestRoute: '/anagrafica/clienti/formazione',
        ListResponseHasData: true,
        GetResponseHasData: false,
        ListDefaultFilter: {},
        OnSaveMap: (x) => { { return x } },
        MenuItems: [

        ],
        campi: [
            {
                nome: 'Training',
                oggetti: ['corso'],
                modificabile: true,
                inputType: 'text',
                class: 'col-lg-3',
                disabledif: { fieldexist: 'id' },
            },
            {
                nome: 'Descrizione',
                oggetti: ['descrizione'],
                modificabile: true,
                inputType: 'text',
                class: 'col-lg-3',
                disabledif: { fieldexist: 'id' },
            },
            {
                nome: 'Docenza',
                oggetti: ['ente'],
                modificabile: true,
                inputType: 'text',
                class: 'col-lg-3',
                disabledif: { fieldexist: 'id' },
            },
            {
                nome: 'Durata',
                oggetti: ['durata'],
                modificabile: true,
                inputType: 'text',
                class: 'col-lg-3',
                disabledif: { fieldexist: 'id' },
            },
            {
                nome: 'Data',
                oggetti: ['data'],
                modificabile: true,
                inputType: 'date',
                class: 'col-lg-3',
                disabledif: { fieldexist: 'id' },
            },
        ],
        Azioni: [
           
        ],
        Headers: [
            { nome: 'Training', oggetti: ['corso'], inputType: 'text' },
            { nome: 'Descrizione', oggetti: ['descrizione'], inputType: 'text' },
            { nome: 'Docenza', oggetti: ['ente'], inputType: 'text' },
            { nome: 'Durata', oggetti: ['durata'], inputType: 'text' },
            { nome: 'Data', oggetti: ['data'], inputType: 'date' },
        ],
        Filters: [],
    }
    return OBJ;
}

export var AttestatiEnv = () => {

    var env = {
        Cruds: [FormazionePersonale()],
        Menu: [...FormazionePersonale().MenuItems],
        FormazioneAddOn: {
            generaAttestati: true,
        },
        Modules: {
            "attestati": true,
        }
    };
    return env;
}