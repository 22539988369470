import { MansioniEnv } from "../Modules/Mansioni";
import { AttestatiEnv } from "../Modules/Attestati";
import { addModule } from "../Utility/ModuleUtility";
import { FabbisognoFormativoEnv } from "../Modules/FabbisognoFormativo";
export const env = {
  Wp: false,
  hasCustomizer:false,
  production: true,
  apiUrl: null,
  bbbUrl: 'https://newsbs.ddns.net/bigbluebutton',
  langPrefix: './assets/i18n/',
  langSuffix: '_LRN.json',
  loginbgPath: 'assets/images/background/teamwork-3213924.jpeg',
  icon:'assets/images/IconEdu.png',
  baseUrl: '/formazione/dashboard/corsi',
  href: '/Login',
  homepages: [
     {Ruoli: ["STU"], link: "/formazione/dashboard/corsi"}
  ],
  logoPath: 'assets/images/LogoEdu.png',
  poweredBy: 'assets/images/LogoEdu.png',
  poweredByLink: '/',
  loginMsg: '',
  availableLang: ['it'],

  layout:"vertical",
  ClientiType: ['C', 'F'],
  IDTecnico: "RT",
  logoBg: 'skin6', // six possible values: skin(1/2/3/4/5/6),
  //navbarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
  sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
  userCircleColor: "",
  theme: 'light',
  dir: 'ltr', // two possible values: ltr, rtl
  sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
  sidebarpos: 'fixed', // two possible values: fixed, absolute
  headerpos: 'fixed', // two possible values: fixed, absolute
  boxed: 'full', // two possible values: full, boxed

  //NuovaGrafica
  MainColor : '#62C4E6',
  navbarbg: '#FFFFFF',
  navbarAccentColor: '#62C4E6',
  style:'FortyFix',
  InputTextColor: "#135666",





  //#region Moduli
  Modules:{
    "twoFactorAuth": false,
    "ForceReset": true,
    "Admin": true,
    "Personale": true,
    "Clienti": true,
    "Interventi": true,
    "Prima_Nota": false,
    "Listini": false,
    "Progetti": true,
    "Prodotti": false,
    "Documenti": false,
    "Scadenzario": false,
    "Spedizioni": false,
    "Stampe": true,
    "Task": true,
    "Shared": true,
    "Dashboard": true,
    "Settings": true,
    "Formazione": true,
    "VersioneCarbotti": false,
    "PartiProdotto": false,
    "Parti": false,
    "Rma": true,
    "Ecommerce": false,
    "ScadenzeFiglie": false,
    "Registrazione": false,
    "Ticket": false,
    "Acquisti": false,
    "taskboard": true,
    "Camera": false,
    "Valutazioni":false
  },
  //#endregion
  //#region Addon
  FormazioneAddOn: {
      light: false,
      hideLight: true,
      hideChiusa: true,
      hideColor: true, 
    usemodal: false,
  },


  TaskAddon: {
    checkList : true,
    listTitle: "Lezioni Online",
    hideDateFilter: true,
    periodo: false,
    Filter: [

    ],
    ListTableHeaderField:[
      {nome: "Corso", oggetti: ['corso'] },
      {nome: "Titolo", oggetti: ['nome'], type: 'replace', replacement: [{original: 'R', fieldexist: 'padre', replace:'Intervento #'},{original: 'R', fieldnotexist: 'padre', replace:'Richiesta #'},{original: 'W', replace:'Attività'},{original: 'C', replace:'CRM'}], color:[] },
      {nome: "Stato", oggetti: ['chiuso'], type: 'replace', replacement:[{original:0, replace:'Lezione Iniziata'}, {original:1, replace:'Lezione Superata'}, {original:false, replace:''}], color:[] },
    ],
  },
  Personale:{
    Tabs: [
      {
        id: 'info', tipo: 'template', titolo: 'INFO', component: 'Edit',
        Azioni: [
          {
            nome: 'Salva',
            close: true,
            class: ' btn ',
            labelClass: 'ml-1 ',
            type: 'POST',
            restRoute: '/anagrafica/persone/update',
            icon: 'far fa-save text-success',
            placeholder: 'Salva',
            hideif: { fieldnotexist: 'id' },
            successMex: 'Salvataggio eseguito con successo',
            sendFullObject: true
          },
          {
            nome: 'Salva',
            close: true,
            class: ' btn ',
            labelClass: 'ml-1 ',
            type: 'POST',
            restRoute: '/anagrafica/persone/add',
            icon: 'far fa-save text-success',
            placeholder: 'Salva',
            hideif: { fieldexist: 'id' },
            successMex: 'Salvataggio eseguito con successo',
            sendFullObject: true
          }
        ],
      },
      { id: 'Corsi', titolo: 'Corsi', tipo: 'template', component: 'Corsi', hideif: { fieldnotexist: 'id' } },
      //{ id: 'Mansioni', titolo: 'Mansioni', tipo: 'crud', crud: 'mansioni', hideif: { fieldnotexist: 'persona_id' } },
      { id: 'Attestati', titolo: 'Attestati', tipo: 'crud', crud: 'formazione_personale', hideif: { fieldnotexist: 'persona_id' } },
     // { id: 'Fabbisogno', titolo: 'Fabbisogno', tipo: 'crud', crud: 'fabbisogno', hideif: { fieldnotexist: 'persona_id' } },
      { id: 'files', tipo: 'template', titolo: 'Files', component: 'Files', hideif: { fieldnotexist: 'id' } }
    ],
    RefTabs: [
      {
        id: 'info',
        titolo: 'Anagrafica',
        tipo: 'sezioni',
        Azioni: [
          {
            nome: 'Salva',
            close: true,
            class: ' btn ',
            labelClass: 'ml-1 ',
            type: 'POST',
            restRoute: '/anagrafica/clienti/referenti/update',
            icon: 'far fa-save text-success',
            placeholder: 'Salva',
            hideif: { fieldnotexist: 'id' },
            successMex: 'Salvataggio eseguito con successo',
            sendFullObject: true
          },
          {
            nome: 'Salva',
            close: true,
            class: ' btn ',
            labelClass: 'ml-1 ',
            type: 'POST',
            restRoute: '/anagrafica/clienti/referenti/add',
            icon: 'far fa-save text-success',
            placeholder: 'Salva',
            hideif: { fieldexist: 'id' },
            successMex: 'Salvataggio eseguito con successo',
            sendFullObject: true
          }
        ],
        sezioni: [
          {
            sezione: "Anagrafica",
            titleStyle: { 'font-weight': "700", 'padding': '0.8rem', 'background-color': 'whitesmoke' },
            campi: [
              {
                nome: "Nome",
                oggetti: ["nome"],
                modificabile: true,
                inputType: "text",
                class: "col-lg-3"
              },
              {
                nome: "Cognome",
                oggetti: ["cognome"],
                modificabile: true,
                inputType: "text",
                class: "col-lg-3"
              },
              {
                nome: "Codice Fiscale",
                oggetti: ["codice_fiscale"],
                modificabile: true,
                inputType: "text",
                class: "col-lg-3"
              },
              {
                nome: "Sede",
                source: '/anagrafica/clienti/referenti/sedi',
                responseHasData: false,
                parameter: [{ nome: "id", parameter: "cliente" }],
                oggetti: ['sedi'],
                internalOutput: 'sediObject',
                OutputField: 'sedi',
                hideif:{fieldnotexist: 'cliente'},
                OnChange: 'OnsediChange',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                  singleSelection: true,
                  closeDropDownOnSelection: true,
                  singleOutput: false,
                  idField: 'id',
                  textField: 'fullAddress',
                  searchPlaceholderText: "Cerca",
                  allowSearchFilter: true,
                  allowRemoteDataSearch: false,
                },
                class: 'col-lg-3',
                visibleifProhibited: true
              }
            ]
          },
          
          
         
          {
            sezione: 'Account',
            titleStyle: { 'font-weight': "700", 'padding': '0.8rem', 'background-color': 'whitesmoke' },
            campi: [
              {
                nome: "Email",
                oggetti: ["email"],
                modificabile: true,
                requiredif: { fieldexist: ['password', 'ruoliAccesso'] },
                inputType: "email",
                class: "col-lg-3"
              },
              {
                nome: "Password",
                oggetti: ["password"],
                modificabile: true,
                requiredif: { fieldexist: ['confPassword'] },
                inputType: "password",
                class: "col-lg-3"
              },
              {
                nome: "Conferma Password",
                oggetti: ["confPassword"],
                modificabile: true,
                requiredif: { fieldexist: ['confPassword'] },
                inputType: "password",
                class: "col-lg-3"
              },
              {
                nome: 'Tipo di Accesso',
                source: '/anagrafica/ruoli/list',
                responseHasData: true,
                parameter: [],
                oggetti: ['ruoliAccesso'],
                requiredif: { fieldexist: ['password', 'ruoliAccesso'] },
                internalOutput: 'ruoliAccessoObject',
                OutputField: 'ruoliAccesso',
                OnChange: 'OnruoliAccessoChange',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                  singleSelection: false,
                  closeDropDownOnSelection: true,
                  singleOutput: false,
                  idField: 'id',
                  textField: 'label',
                  searchPlaceholderText: "Cerca",
                  allowSearchFilter: true,
                  allowRemoteDataSearch: false,
                },
                class: 'col-lg-3',
                visibleifProhibited: true
              },
            ]
          }
        ]
      },
      { id: 'Corsi', titolo: 'Corsi', tipo: 'template', component: 'Corsi', hideif: { fieldnotexist: 'id' } },
     // { id: 'Mansioni', titolo: 'Mansioni', tipo: 'crud', crud: 'mansioni', hideif: { fieldnotexist: 'persona_id' } },
      { id: 'Attestati', titolo: 'Attestati', tipo: 'crud', crud: 'formazione_personale', hideif: { fieldnotexist: 'persona_id' } },
      // { id: 'Fabbisogno', titolo: 'Fabbisogno', tipo: 'crud', crud: 'fabbisogno', hideif: { fieldnotexist: 'persona_id' } },
      { id: 'files', tipo: 'template', titolo: 'Files', component: 'Files', hideif: { fieldnotexist: 'id' } }
    ]
  },
  ClientiAddOn: {
    codAdhoc:false,
    enable: false,
    export: true,
    importReferenti:true,
    InvioCredReferenti: true,
    info:true,
    commerciale: false,
    segnalatore: false,
    segnalatoreInterno: false,
    sedi: true,
    TipoSedePrimaria: false,
    attivita: false,
    geolocalizzazione: false,
    ruolo: false,
    Modifica: false,
    prodotti: false,
    rma: false,
    accesso: false,
    referenti: true,
    interventi: false,
    note_private:false,
    rating : false,
    ruoliAccesso:false,
    compilazione:false,
    luogoNascita: false,
    dataNascita: false,
    statoCivile:false,
    titoloStudio: false,
    occupazione: false,
    invalidita: false,
    legge104: false,
    diagnosi: false,
    accompagnamento:false,
    pensione: false,
    tutelare: false,
    medico: false,
    telMedico: false,
    terapia:false,
    repartoReferente: true,
    sediReferente: true,
    CampiReferenti: [
      
    ]
  },
  ProdottiAddOn: {
    sottoTitolo: false,
    shortDescription: false,
    classeEnergetica: false,
    tags: false,
    video: false,
    dettaglio:false,
    files: true,
    cart: false,
    codice: false,
    codice_produttore: false,
    tipo: true,
    standby: false,
    tipi:false, // tipi di prodotto (1 - 2ante klima)
    cat_image: true,
    lista_prezzo:true,
    TranslatedDesc: false,
    codAdhoc:false,
    DragSort: false,
    cat_Sort: false
  },
  legenda:[],
  filtrife: [

  ],
  CalendarAddon: {
    managerScadenza : true,
  },
  PartiAddOn: [false],//  categorie associate
  RmaAddOn: {
    taskGenerico: true,
    vociDiCosto: false,
    provaAcquisto:true,
    CategoriaProdotto: true,
    TipoAcquisto: false,
    TipoAcquistoSource: "ClientiType",
    Rivenditore:false,
    NumeroDocumento: false,
    DataDocumento: false,
    Ricevuta: false,
    Targhetta: false,
    CircostanzaDifetto: false,
    DescrizioneDifetto: false,
    Difetto: true,
    Telefono: true,
    Garanzia: true,
    EstensioneGaranzia: true,
    Allegati: true,
    Modello: true,
    Matricola: true,
    Brand: true,
    sottoTask: true,
    headersScadenza:[
      {nome: "Titolo", oggetti: ["nome"]},
      {nome: "Responsabile", oggetti: ['manager'], type: "object", field:['fullName'] },
      {nome: "Chiuso", oggetti: ['data_fine_effettiva'], inputType: 'datetime-local', type: "boolean"},
    ],
    AzioniScadenza:[

      {nome: '', class:' ',  labelClass:'ml-1 text-orange font-weight-bold', type: 'POST', restRoute: '/rma/segnalazione/prendiincarico', capacita: [], icon:'fas fa-user text-orange', placeholder: 'Prendi in carico', successMex: "Preso in carico", parameter: [{nome:"id", parameter: 'id'}, {nome: 'presaInCarico', value: true}]},
      {nome: '', class:' ',  labelClass:'ml-1 text-danger font-weight-bold', type: 'POST', restRoute: '/rma/segnalazione/delete', preAlert: 'Sei sicuro di voler eliminare questo elemento?', capacita: ['gestione-taskboard'], icon:'fas fa-trash text-danger', placeholder: 'Elimina', successMex: 'Elemento eliminato con successo', parameter: [{nome:"id", parameter: 'id'}]},

    ],
    campiScadenza: [
      {
        modificabile: true,
        nome: 'Responsabile',
        oggetti: ['manager'],
        inputType: 'singleDropdown',
        source: '/anagrafica/persone/list',
        responseHasData: true,
        parameter: [{nome:"page", value: 1}, {nome:"size", value: 200}],
        internalOutput: 'managerObject',
        OnFilter: 'xx',
        OnChange: 'xxy',
        OutputField: 'manager',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        class: 'col-lg-3',
        visibleifProhibited: true,
      },
      {nome: "Files", type: "Files", url:"/rma/segnalazione/file", modificabile:true, class: 'col-lg-12', OnChange: 'OnFilesChange'},
    ],
    Ritiro: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Cliente: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true,
      
    },
    Assistenza: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true,
      Tecnico: false,
    }
  },
  ProgettiAddon: {
    commerciale: true,
    segnalatore: true,
    categoriaVoceDiCosto: true,
    lavorato: true,
    CampiOfferta: [
      {
        modificabile: true,
        nome: 'Offerta.Responsabile',
        oggetti: ['manager'],
        inputType: 'singleDropdown',
        source: 'managers',
        internalOutput: 'managerObject',
        OnFilter: 'filtroManager',
        OnChange: 'OnManagerChange',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'cliente.commerciale',
        oggetti: ['commerciale'],
        inputType: 'singleDropdown',
        source: 'managers',
        internalOutput: 'commerciale',
        OnFilter: 'filtroCommerciale',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'cliente.segnalatore',
        oggetti: ['clienti'],
        inputType: 'singleDropdown',
        source: 'clienti',
        internalOutput: 'segnalatore',
        OnFilter: 'filtrosegnalatore',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'nominativo',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
          noDataAvailablePlaceholderText: "Nessun Cliente trovato ",
          moreButton: true,
          moreButtonText: "Cerca in AdHoc",
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Colore',
        oggetti: ['colore'],
        inputType: 'color',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-1',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Lavorato',
        oggetti: ['lavorato'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-1',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Oggetto:',
        oggetti: ['oggetto'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Numero Ordine:',
        oggetti: ['codice'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-2',
        placeholder: 'Numero Ordine:',
        visibleifProhibited: true
      },
      {
        prevDivClass: "col-md-12",
        modificabile: true,
        nome: 'Offerta.Creazione',
        oggetti: ['data_creazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Offerta',
        oggetti: ['data_emissione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Accettazione',
        oggetti: ['data_accettazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Conferma',
        oggetti: ['data_conferma'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Note',
        oggetti: ['note'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-6',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Note Private',
        oggetti: ['note_private'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-6',
        visibleifProhibited: false
      },
      //   {
      //   modificabile:true,
      //   nome: 'Gestore',
      //   oggetti: ['manager'],
      //   inputType: 'singleDropdown',
      //   source: 'managers',
      //   internalOutput: 'gestoreObject',
      //   OnFilter: 'filtroCommerciale',
      //   placeholder: 'Seleziona',
      //   settings: {
      //     singleSelection: true,
      //     closeDropDownOnSelection: true,
      //     singleOutput:true,
      //     idField: 'id',
      //     textField: 'fullName',
      //     searchPlaceholderText: "inserire almeno 3 caratteri",
      //     allowSearchFilter: true,
      //     allowRemoteDataSearch: true,
      //   },
      //   titleClass: 'control-label col-form-label'  ,
      //   class: 'col-lg-3',
      //   visibleifProhibited: true
      //
      // },
    ],
    CampiSottoOfferta: []
  },
  //#endregion
  Menu: [
    {
      path: "formazione/dashboard/corsi",
      title: "Dashboard",
      icon: "icon-dashboard",
      capacita: []
    },
    {
      module: "Formazione",
      path: "formazione/corsi",
      title: "menu.corsi",
      icon: "icon-corsi",
    },
    {
      module: "Formazione",
      title: 'Contenuti',
      icon: 'icon-contenuti',
      path: "formazione/media",
      capacita: ["gestione-formazione"]
    },
    {
      module: "Personale",
      path: "/listautenti/personale",
      title:'menu.PersonaleLabel',
      icon: "far fa-user",
      capacita: ["menu-persone"]
    },
    {
      module: "Clienti",
      path: "/listautenti/aziende/C",
      title:'menu.clienti',
      icon: "mdi mdi-account-multiple",
      capacita: ["menu-clienti"]
    },
    {
      module: "Stampe",
      path: "stampe/templates",
      title: "menu.StampeLabel",
      icon: "mdi mdi-printer",
      class: "has-arrow",
      capacita: ["menu-stampa","menu-blocchi"],
      submenu: [
        {
          path: "stampe/templates",
          title: "Templates",
          capacita: ["gestione-stampa"]
        },
        {
          path: "stampe/marker",
          title: "Marker",
          capacita: ["menu-stampa"]
        },
        {
          path:"/blocchi/blocchi/list",
          title: "Blocchi",
          capacita: ["menu-blocchi"]
        }
      ]
    },
    {
      module: "Settings",
      path: "settings/settings",
      title: "menu.SettingsLabel",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Settings",
      path: "settings/smtp",
      title: "menu.smtp",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Admin",
      path: "listautenti/roles",
      title: "Ruoli",
      icon: "mdi mdi-lock-open-outline",
      capacita: ["menu-ruoli"]
    },
    {
      module: "Ticket",
      path: "ticket/list",
      title: "Tickets",
      icon: "mdi mdi-bookmark-outline",
      capacita: ["menu-ticket"]
    },
    
    {
      ruoliOperativi:["AC","C","CA"],
      path: 'Cliente/profile',
      title: "Area Riservata",
      icon: 'mdi mdi-cart',
    }
  ],

};
var Environment = () => { 
  var newEnv = addModule(AttestatiEnv(), env);
  
  return newEnv;
};
export const environment = Environment();